@import 'variables';

.node-content-wrapper:hover {
  background: transparent !important;
}

.node-content-wrapper-active,
.node-content-wrapper-focused,
.node-content-wrapper:hover {
  box-shadow: none !important;
}

.node-content-wrapper-focused {
  background: transparent !important;
}

.node-content-wrapper-active,
.node-content-wrapper.node-content-wrapper-active:hover,
.node-content-wrapper-active.node-content-wrapper-focused {
  background: transparent !important;
}

.node-content-wrapper label:hover {
  text-decoration: underline;
}

.node-content-wrapper.is-dragging-over {
  background: transparent !important;
  box-shadow: none !important;
}

.node-drop-slot.is-dragging-over {
  background: none !important;
  height: 0px !important;
  border: 0px dotted #888 !important;
}

.tree-inner .toggle-children-wrapper {
  position: relative;
  top: 4px;
}

.tree-inner .folder-name-count {
  padding-left: 10px;
  line-height: 1;
  margin-bottom: 0;
  font-weight: 700;
  position: relative;
  top: 2px;
}

.tree-inner .folder-icon-blank {
  position: relative;
  top: 3px;
}
