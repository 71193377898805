@import '../variables/variables';

@mixin w-o-color() {
  color: $w-o-color;
}

@mixin w-ol-color() {
  color: $w-ol-color;
}

@mixin fs-button(
  $bg-color: $w-o-color,
  $color: $l-blk-color,
  $bg-color-hover: $w-color,
  $color-hover: $l-blk-color,
  $bg-color-disabled: $w-color,
  $color-disabled: $b-color
) {
  border-radius: 50px;
  background-color: $bg-color;
  text-transform: uppercase;
  color: $color;
  padding: 5px 16px;
  min-width: 80px;
  transition: 0.5s all;
  outline: none !important;

  &:not(:disabled):hover {
    background-color: $w-color;
    color: $b-color;
  }

  &:hover,
  &:active {
    outline: none;
    box-shadow: none;
    background: $bg-color-hover;
    color: $color-hover;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }
}

@mixin fs-txt-hover($defaultColor, $hoveredColor) {
  color: $defaultColor !important;
  &:hover {
    transition: 0.5s all !important;
    color: $hoveredColor !important;
  }
}
