@import 'variables';

.owl-date-time-calendar div,
.owl-date-time-calendar span {
  color: $b-color !important;
}

.owl-date-time-calendar div:hover,
.owl-date-time-calendar span:hover {
  color: $b-color !important;
}
.owl-dt-calendar-multi-year-view {
  display: -webkit-inline-box !important;
}

/* Helght issue for calender owl-date-time plug-in */

.calender-text {
  min-height: 28px;
}

.pi.pi-times {
  display: none !important;
}

.fs-datepicker-default {
  border-radius: 4px;

  .mat-mdc-form-field-infix {
    min-height: auto !important;
    padding: 0 !important;
  }

  .mdc-text-field--filled {
    background-color: $b-light-gray !important;
  }

  .mat-mdc-form-field-input-control {
    font-size: 16px !important;
  }

  .mat-mdc-form-field-flex {
    display: flex !important;
    align-items: center !important;
    height: 100% !important;
  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }

  .mat-mdc-icon-button.mat-mdc-button-base {
    width: 24px !important;
    height: 24px !important;
    padding: 0 !important;
  }
}
