@import 'variables';

.fs-accordion {
  &-rotate-arrow-down {
    transform: rotate(90deg) !important;
  }

  &-dark-theme {
    .mat-expansion-panel-header.mat-expansion-toggle-indicator-before
      .mat-expansion-indicator {
      margin: 0 8px 0 0 !important;
    }

    .mat-expansion-panel-header {
      background: $d-blk-color !important;
      padding: 0 0.5rem;
    }

    .mat-expansion-panel-header-title {
      color: $w-o-color !important;
    }

    .mat-expansion-indicator::after {
      color: $w-o-color !important;
    }

    .mat-expansion-panel:first-of-type {
      border-top-right-radius: 0 !important;
      border-top-left-radius: 0 !important;
    }

    .mat-expansion-panel:last-of-type {
      border-bottom-right-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }

    .mat-expansion-panel-body {
      background: $l-blk-color;
      padding: 0;
    }

    .mat-expansion-toggle-indicator-before
      .mat-content.mat-content-hide-toggle {
      margin-left: 0px !important;
    }
  }
}
