@import 'variables';

.header {
  .main-header {
    position: relative;
    z-index: 1000;
    .dropdown-menu {
      background-color: #515151;
      div > a {
        color: $w-o-color;
        &:hover,
        &:focus {
          background-color: transparent;
          color: $w-color;
        }
      }
    }
    .dept-dropMenu {
      > a {
        color: $w-o-color;
        &:hover,
        &:focus {
          text-decoration: none;
        }
      }
      .caret {
        border-top: 10px dashed;
        border-right: 7px solid transparent;
        border-left: 7px solid transparent;
        margin-right: 5px;
      }
    }
    .breadcrumb {
      background-color: transparent;
      margin: $marg-0;
      padding: $padd-0;
      li + li {
        &::before {
          color: $w-o-color;
          padding: $padd-0;
        }
      }
      a,
      .active {
        color: $w-o-color;
        &:hover,
        &:focus {
          color: $w-color;
          text-decoration: none;
        }
      }
    }
  }
}

.fs-header-wrapper_fixed tr th {
  background-color: $header-table-bg-color;
  color: #555 !important;
  font-size: 13px;
  font-family: 'Roboto', 'Trebuchet MS', Arial, Helvetica, sans-serif;
  padding: 3px;
  font-weight: normal;
}

.fs-header-wrapper_fixed tr td {
  color: #fff !important;
  padding: 3px;
}

.fs-help-global-icon {
  font-size: 13px;
  position: absolute;
  cursor: pointer;
  transition: 0.1s all;
  animation: infoscale 1s ease-in-out infinite alternate;
  -webkit-animation: infoscale 1s ease-in-out infinite alternate;
}

.global-help-icon-wraper {
  position: relative;
}

@keyframes infoscale {
  0% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
  25% {
    transform: scale(1.2);
    -webkit-transform: scale(1.2);
    color: yellow;
  }
  75% {
    transform: scale(1.4);
    -webkit-transform: scale(1.4);
    color: orange;
  }
  100% {
    transform: scale(1.5);
    -webkit-transform: scale(1.5);
    color: red;
  }
}
