body {
  // Login Page Realted Css
  .strip-1,
  .strip-2,
  .strip-3 {
    display: none;
  }
  .form-group {
    .form-control {
      font-size: 13px;
      background: #dbdbdb;
    }
  }
  .dropdown-menu {
    .scroll_section {
      div {
        .ng-star-inserted {
          a {
            font-size: 13px;
            padding: 0px !important;
          }
        }
      }
    }
  }
  .send-email-body,
  .link-email-body {
    .send-mail-msg {
      margin-top: 10px;
    }
  }
  .fs-chips {
    font-size: 13px;
  }
  .check-height {
    min-height: 27px;
  }
  .owl-dt-calendar-table .owl-dt-calendar-cell-selected {
    color: #ffffff !important;
  }
  .note-msg-type {
    form {
      position: relative;
    }
  }
  .pl-0 {
    padding-left: 0px !important;
  }
  .pr-0 {
    padding-right: 0px !important;
  }
  .phone_icons {
    max-width: 18px;
    opacity: 0.4;
    &:hover {
      cursor: pointer;
      opacity: 1;
    }
  }
  .header .main-header .dropdown-menu div > a:hover,
  .header .main-header .dropdown-menu div > a:focus {
    color: #ffffff !important;
  }
  .global-help-icon-wrapper {
    display: inline-flex;
    padding-left: 20px;
    text-transform: uppercase;
  }
  .left-menu-img-with-txt {
    img {
      display: inline-block;
      width: 16px;
      margin-right: 5px;
      filter: brightness(0.7);
    }
  }
  .album-tag-scroll {
    div {
      .cb-title {
        max-width: calc(100% - 35px);
        line-height: 16px;
      }
    }
  }

  .settings-container {
    max-height: calc(100vh - 395px);
    overflow-x: hidden;
  }
}
