@import 'variables';

.fs-cursor {
  &-move {
    cursor: move;
  }

  &-pointer {
    cursor: pointer;
  }

  &-default {
    cursor: default;
  }
}

.fs-border-none {
  // todo probably replace with bootstrap later
  border: none !important;
}

.fs-overflow-auto {
  overflow: auto !important;
}

.fs-element-highlighted {
  color: $w-color !important;
}

.fs-text-underline {
  text-decoration: underline;
}

.fs-break-word {
  word-break: break-word !important;
}

.fs-overflow-wrap-break-word {
  overflow-wrap: break-word;
}

.fs-word-break-all {
  word-break: break-all !important;
}

.fs-break-word {
  word-break: break-word;
}
