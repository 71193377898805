@import 'variables';

$side-menu-list-photo-nav-admin-height: 540px;
$side-menu-list-photo-nav-exec-height: 440px;
$side-menu-list-admin-height: 450px;
$side-menu-list-exec-height: 430px;

.photo-nav {
  .mainrole_admin {
    .sub-menu-list {
      min-height: calc(100vh - 640px) !important;
    }
    .main-role-admin-wide {
      .sub-menu-list {
        min-height: calc(100vh - 450px) !important;
      }
      .note-msg-type {
        padding: 10px 20px 10px 10px;
      }
    }
    .main-role-admin-signoff {
      .sub-menu-list {
        min-height: calc(100vh - 320px) !important;
      }
      .note-msg-type {
        padding: 10px 20px 10px 10px;
      }
    }
  }
  .main-role-exec {
    .sub-menu-list {
      min-height: calc(100vh - 570px) !important;
    }
    .main-role-exec-wide {
      .sub-menu-list {
        min-height: calc(100vh - 450px) !important;
      }
      .note-msg-type {
        padding: 10px 20px 10px 10px;
      }
    }
    .main-role-exec-signoff {
      .sub-menu-list {
        min-height: calc(100vh - 320px) !important;
      }
      .note-msg-type {
        padding: 10px 20px 10px 10px;
      }
    }
  }
  .main-role-talent {
    overflow: auto;
    max-height: calc(100vh - 120px);
    .note-msg-type {
      padding-right: 0px !important;
      ::-webkit-scrollbar {
        width: 0 !important;
      }
    }
  }
  .main-role-talent::-webkit-scrollbar {
    width: 10 !important;
  }
}

.without_photonav {
  .mainrole_admin {
    .sub-menu-list {
      min-height: calc(100vh - 550px) !important;
    }
    .main-role-admin-wide {
      .sub-menu-list {
        min-height: calc(100vh - 450px) !important;
      }
      .note-msg-type {
        padding: 10px 20px 10px 10px;
      }
    }
    .main-role-admin-signoff {
      .sub-menu-list {
        min-height: calc(100vh - 320px) !important;
      }
      .note-msg-type {
        padding: 10px 20px 10px 10px;
      }
      .image-notes-ids {
        padding: 10px 0px 0px 0px;
      }
    }
  }
  .main-role-exec {
    .sub-menu-list {
      min-height: calc(100vh - 530px) !important;
    }
    .main-role-exec-wide {
      .sub-menu-list {
        min-height: calc(100vh - 395px) !important;
      }
      .note-msg-type {
        padding: 10px 20px 10px 10px;
      }
    }
    .main-role-exec-signoff {
      .sub-menu-list {
        min-height: calc(100vh - 320px) !important;
      }
      .note-msg-type {
        padding: 10px 20px 10px 10px;
      }
    }
  }
  .main-role-talent {
    overflow: auto;
    max-height: calc(100vh - 120px);
    .note-msg-type {
      padding-right: 0px !important;
      ::-webkit-scrollbar {
        width: 0;
      }
    }
  }
  .main-role-talent::-webkit-scrollbar {
    width: 10 !important;
  }
}

.albums {
  div {
    a {
      position: relative;
      margin: 0 23px;
      .num,
      .right-numb {
        position: absolute;
        top: 3px;
      }
      .num {
        left: 0;
      }
      .right-numb {
        right: 0;
      }
    }
  }
}

.sngl-data-note div div dd:hover span {
  color: rgba(255, 255, 255, 0.4) !important;
}

.sngl-data-note div div dt:hover span {
  color: rgba(255, 255, 255, 0.4) !important;
}

.basic-filter div div:hover span {
  color: rgba(255, 255, 255, 0.4) !important;
}

.sngl-data-note div div dt {
  color: rgba(255, 255, 255, 0.4) !important;
}

.sngl-data-note {
  display: none;
  div {
    div {
      dt {
        float: left;
        width: 100px;
      }
      dd {
        margin-left: 100px;
        color: $w-color;
        max-width: 190px;
        float: inherit;
        word-break: break-all;
        span {
          display: block;
        }
      }
    }
  }
}

.sidebar .wrap-inner {
  overflow: hidden;
}

#sidebar .right-content .extra-menu-link {
  top: -34px;
}

#sidebar {
  .extra-menu-link.data-tabs {
    top: 10px;
    div {
      div {
        display: inline-block;
        a {
          padding: 5px 10px !important;
          text-decoration: none;
          line-height: 1;
        }
        + div {
          position: relative;
          &:before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 2px;
            background: #696262;
            height: 12px;
            margin: auto;
          }
        }
      }
    }
  }
}

.note-msg-type {
  padding: 0 10px 10px;
  textarea {
    width: 100%;
    background: $w-o-color;
    border-radius: 4px;
    line-height: 1.2;
  }
  .nmt-btm {
    .mf-sngle {
      float: left;
      div {
        div {
          display: inline-block;
          vertical-align: middle;
          padding: 6px 15px 6px 0;
        }
      }
    }
    .right-save-btn {
      button,
      a {
        display: inline-block;
        padding: 5px 15px;
        background: $w-o-color;
        font-weight: 400;
        height: 23px;
        text-transform: uppercase;
        font-size: 13px;
        letter-spacing: 1px;
        line-height: 1;
        border-radius: 40px;
        &:hover {
          background: $w-color;
        }
      }
    }
  }
}

.messages-sec {
  .single-message {
    margin-bottom: 15px;
    .fs-msg-wrapper {
      padding: 6px 12px;
      line-height: 1;
      font-size: 13px;
      background: $g-color;
      color: $w-o-color;
      position: relative;
      margin-top: 10px;
      border-radius: 10px 10px 10px 0;
      .sm-msg {
        margin-bottom: 5px;
      }
      .sm-btm {
        font-size: 10px;
        color: #767676;
      }
      &:before {
        content: '';
        position: absolute;
        bottom: -10px;
        left: 0;
        border-right: 15px solid transparent;
        border-top: 10px solid $g-color;
      }
    }
    .my-comment {
      padding: 6px 12px;
      line-height: 1;
      font-size: 13px;
      background: $g-color;
      color: $w-o-color;
      position: relative;
      border-radius: 10px 10px 0 10px;
      .sm-msg {
        margin-bottom: 5px;
      }
      .sm-btm {
        font-size: 10px;
        color: #767676;
      }
      &:after {
        content: '';
        position: absolute;
        bottom: -18px;
        right: 0;
        border-left: 15px solid transparent;
        border-top: 18px solid $g-color;
      }
    }
  }
}

.sidebar-header {
  a:hover,
  span:hover {
    -webkit-transition: none !important;
    transition: none !important;
    color: rgba(255, 255, 255, 0.8) !important;
  }
}
