@import 'variables';

$spinner-color: rgb(0, 172, 193);
$spinner-width: 60px;
$spinner-height: 60px;

.fs-spinner-overlay-panel {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $b-o-black;
  transition-property: opacity;
  transition-duration: 0.5s;
}

.fs-spinner-ball-spin-clockwise {
  display: block;
  width: $spinner-width;
  height: $spinner-height;
  font-size: 0;
  color: $spinner-color;
}

.fs-spinner-ball-spin-clockwise,
.fs-spinner-ball-spin-clockwise > .fs-spinner-ball-spin-clockwise-ball {
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.fs-spinner-ball-spin-clockwise > .fs-spinner-ball-spin-clockwise-ball {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 25%;
  height: 25%;
  float: none;
  margin-top: -4px;
  margin-left: -4px;
  border-radius: 100%;
  border: 0 solid currentColor;
  background-color: currentColor;
  -webkit-animation: fs-ball-spin-clockwise 1s infinite ease-in-out;
  -moz-animation: fs-ball-spin-clockwise 1s infinite ease-in-out;
  -o-animation: fs-ball-spin-clockwise 1s infinite ease-in-out;
  animation: fs-ball-spin-clockwise 1s infinite ease-in-out;
}

.fs-spinner-ball-spin-clockwise
  > .fs-spinner-ball-spin-clockwise-ball:nth-child(1) {
  top: 5%;
  left: 50%;
  -webkit-animation-delay: -0.875s;
  -moz-animation-delay: -0.875s;
  -o-animation-delay: -0.875s;
  animation-delay: -0.875s;
}

.fs-spinner-ball-spin-clockwise
  > .fs-spinner-ball-spin-clockwise-ball:nth-child(2) {
  top: 18.1801948466%;
  left: 81.8198051534%;
  -webkit-animation-delay: -0.75s;
  -moz-animation-delay: -0.75s;
  -o-animation-delay: -0.75s;
  animation-delay: -0.75s;
}

.fs-spinner-ball-spin-clockwise
  > .fs-spinner-ball-spin-clockwise-ball:nth-child(3) {
  top: 50%;
  left: 95%;
  -webkit-animation-delay: -0.625s;
  -moz-animation-delay: -0.625s;
  -o-animation-delay: -0.625s;
  animation-delay: -0.625s;
}

.fs-spinner-ball-spin-clockwise
  > .fs-spinner-ball-spin-clockwise-ball:nth-child(4) {
  top: 81.8198051534%;
  left: 81.8198051534%;
  -webkit-animation-delay: -0.5s;
  -moz-animation-delay: -0.5s;
  -o-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

.fs-spinner-ball-spin-clockwise
  > .fs-spinner-ball-spin-clockwise-ball:nth-child(5) {
  top: 94.9999999966%;
  left: 50.0000000005%;
  -webkit-animation-delay: -0.375s;
  -moz-animation-delay: -0.375s;
  -o-animation-delay: -0.375s;
  animation-delay: -0.375s;
}

.fs-spinner-ball-spin-clockwise
  > .fs-spinner-ball-spin-clockwise-ball:nth-child(6) {
  top: 81.8198046966%;
  left: 18.1801949248%;
  -webkit-animation-delay: -0.25s;
  -moz-animation-delay: -0.25s;
  -o-animation-delay: -0.25s;
  animation-delay: -0.25s;
}

.fs-spinner-ball-spin-clockwise
  > .fs-spinner-ball-spin-clockwise-ball:nth-child(7) {
  top: 49.9999750815%;
  left: 5.0000051215%;
  -webkit-animation-delay: -0.125s;
  -moz-animation-delay: -0.125s;
  -o-animation-delay: -0.125s;
  animation-delay: -0.125s;
}

.fs-spinner-ball-spin-clockwise
  > .fs-spinner-ball-spin-clockwise-ball:nth-child(8) {
  top: 18.179464974%;
  left: 18.1803700518%;
  -webkit-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s;
}

@keyframes fs-ball-spin-clockwise {
  0%,
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 0;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
  }
}
