@import 'variables';

.snack-bar-bg-color-panel {
  .mdc-snackbar__surface {
    background-color: $blk-color-3;
  }
}

.snack-bar-color-panel {
  .mdc-snackbar__label {
    color: $red !important;
  }
}

.snack-bar-yellow-panel {
  background-color: $yellow !important;
  color: $black !important;

  .mdc-snackbar__surface {
    background-color: $yellow !important;
  }

  .mdc-snackbar__label {
    color: $black !important;
  }
}
