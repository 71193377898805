@import 'variables';
@import 'src/style/mixin/responsive-mixins';

$cookie-container-bg-color: #2b2a32;
$cookie-btn-bg-color: #e84118;
$cookie-btn-color: #f5f5f5;
$cookie-container-text-color: #f5f6fa;

.fs-active-cookie-container {
  bottom: 0 !important;
  background-color: $cookie-container-bg-color;
}

.fs-close-cookie-btn {
  font-size: 18px !important;
}

.fs-cookie-container {
  bottom: -100%;
  position: fixed;
  right: 0;
  width: 30% !important;
  border-radius: 4px;
  transition: 400ms;
}

.fs-cookie-description-container {
  font-weight: 400;
  line-height: 1.5;
  font-size: 14px;

  @include size-md-down {
    margin-bottom: 0 !important;
  }
}

.fs-cookie-container {
  background-color: #424241;

  @include size-l-down {
    width: 400px !important;
  }
  @include size-md-down {
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 100% !important;
  }
}

.fs-btn-color-danger {
  background-color: $bg-color-red;
  color: $white;
}
