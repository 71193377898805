.slider-wrapper {
  &.mat-mdc-slider {
    width: 95%;
  }

  .mdc-slider__track {
    border-radius: 10px;
    height: 0.8em !important;
    background: #494949 !important;
  }

  .mdc-slider__track--active_fill {
    border-radius: 10px;
    height: 0.8em !important;
    background-color: #14a4ff !important;
    border-color: #14a4ff !important;
  }

  .mdc-slider__track--active {
    height: 0.8em !important;
  }

  .mdc-slider__track--inactive {
    background-color: transparent !important;
  }

  &.mat-mdc-slider .mdc-slider__thumb-knob {
    background: url('../../assets/images/slider-handle.png') !important;
    top: 16px !important;
    left: 14px !important;
    border-color: transparent !important;
    width: 16px !important;
    height: 16px !important;
    transform: scale(1);
    border-style: none !important;
    border-width: 0 !important;
    background-repeat: no-repeat !important;
  }

  .mat-ripple {
    display: none !important;
  }
}
