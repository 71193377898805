@import 'variables';

.dropdown-menu {
  background-color: #1b1b1b !important;
  border: 1px solid rgba(255, 255, 255, 0.4);
}

.dropdown-menu > div > a {
  color: rgba(255, 255, 255, 0.4);
}

.dropdown-item:hover .left-menu-img-with-txt {
  color: #666666 !important;
}

.dropdown-menu .dropdown-item span {
  color: #666666 !important;
}

.dropdown-item:hover .left-menu-img-with-txt img {
  filter: brightness(0.15);
}

.dept-dropMenu .ng-dropdown-panel-items {
  background-color: #494949 !important;
}

.dept-dropMenu .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  background-color: #494949 !important;
  white-space: initial;
  font-size: 12px;
  line-height: 18px;
}

.dept-dropMenu .ng-select .ng-option {
  color: rgba(255, 255, 255, 0.4) !important;
  text-align: left;
  padding: 2px 8px !important;
}

.dept-dropMenu .ng-select .ng-option-marked {
  color: #fff !important;
}

.dept-dropMenu .ng-select-container,
.dept-dropMenu .ng-dropdown-header {
  background: #585858 !important;
  border: 1px solid #646464 !important;
}

.dept-dropMenu .ng-dropdown-panel {
  border: none;
}

.dept-dropMenu .ng-dropdown-panel .ng-dropdown-panel-items {
  max-height: 280px !important;
}

.dept-dropMenu .ng-clear-wrapper {
  display: none;
}

.fs-drop-down-no-padding {
  .mat-menu-content {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

.fs-drop-down-menu {
  margin-top: 0;
  min-width: 100%;
  border-radius: 0 !important;
  background-color: $l-blk-color !important;
  box-shadow: inset 0 0 10px 0 $shadow-clr;
  border: 1px solid $w-o-color;
}

.fs-drop-down-btn {
  background: $lg-color1;
  border-radius: 4px;
  > div {
    padding: 5px 7px;
    color: rgba(255, 255, 255, 0.4);
    text-decoration: none;
    display: block;
    font-weight: 500;
    letter-spacing: 1px;
    position: relative;
    height: 35px;
    font-size: 13px;

    > .fa-caret-down {
      right: 15px;
      top: 7px;
      font-size: 20px;
      vertical-align: middle;
      position: absolute;
    }
  }
}

.fs-drop-down-btn.open {
  div {
    .fa-caret-down {
      transform: rotate(180deg);
    }
  }
}

.fs-context-menu-container {
  border-radius: 4px !important;
  min-height: auto !important;

  .mat-menu-content {
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 14px;
    text-align: left;
    list-style: none;
    border: 1px solid $color-e-white;
    border-radius: 4px;
    box-shadow: 0 6px 12px $shadow-light-color;

    .mat-menu-text {
      display: block;
      padding: 3px 20px;
      clear: both;
      height: auto;
      color: $w-o-color;
      font-weight: 400;
      line-height: 1.428571429;
      white-space: nowrap;

      &:hover {
        color: $color_star_dust_approx !important;
        text-decoration: none;
        border-radius: 8px;
        background-color: $b-light-gray;

        .mat-menu-item .mat-icon-no-color,
        .mat-menu-submenu-icon {
          color: $color_star_dust_approx !important;
        }
      }
    }
  }

  .mat-menu-item[disabled] {
    opacity: 0.5;
  }

  .mat-menu-item .mat-icon-no-color,
  .mat-menu-submenu-icon {
    color: $w-o-color;
    width: 10px;
    height: 15px;
    margin-right: -10px;
  }
}
