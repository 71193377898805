@import 'variables';
@import 'mixin';

@media (max-width: $breakpoint-desktop-med) {
  .mf-sngle {
    button {
      padding: 5px 15px;
    }
  }
}

@media (max-width: $breakpoint-desktop-small) {
  .modal-footer {
    padding: 5px 15px;
    .ftr-btns {
      border-top: 1px solid #3e3e3e;
      padding-top: 5px;
      margin-top: 2px;
    }
  }
}

@media (max-width: $breakpoint-laptops-small) {
  .search-results {
    flex-direction: row;
    .fs-project-item-container {
      flex: 0 0 100% !important;
      max-width: 100% !important;
    }

    .department-block,
    .project-block {
      width: auto !important;
    }

    .project-title-favorite {
      right: 40px !important;
    }
  }

  .favourite-block {
    width: 100% !important;
  }
}

@media (max-width: $breakpoint-mobile-med) {
  .block-header {
    display: flex;
    justify-content: space-between;
  }
}

@media (min-width: $breakpoint-tablet) {
  .single-filter {
    div {
      display: block !important;
    }
  }
}

@media (max-width: $breakpoint-mobile) {
  .right-flag {
    .cb-right {
      position: static;
      display: inline-block;
      margin-left: 5px;
      vertical-align: middle;
    }
  }
  .single-filter {
    h4 {
      display: block;
    }
    h4.open {
      color: $w-color;
      &:after {
        content: '\f106';
      }
    }
    div {
      display: none;
      padding: 10px 0;
      border-top: 1px solid #565656;
      div {
        display: inline-block;
        vertical-align: top;
        border: 1px solid #525252;
        padding: 0 10px 0 7px;
        background: #3e3e3e;
        margin: 0 0 3px 0;
      }
    }
    + .single-filter {
      h4 {
        border-top: 1px solid #565656;
      }
    }
  }
  .image-gallery {
    max-height: inherit;
  }
}

@media (max-width: $breakpoint-mobile-med) {
  .btm-images {
    div {
      div {
        width: 33.333%;
        .is-name {
          font-size: 13px;
        }
      }
    }
  }
}
@media (max-width: $breakpoint-mobile-small) {
  .btm-images {
    div {
      div {
        width: 50%;
        .is-name {
          font-size: 12px;
        }
      }
    }
  }

  .mid-flds {
    div.mf-sngle {
      label.custom-radio {
        input[type='radio'] {
          + .cb-title {
            font-size: 14px;
            margin-right: 10px;
          }
        }
      }
    }
  }
  .number-of-views {
    input[type='text'] {
      width: 75px;
    }
  }
  .messages-sec {
    .single-message {
      .sm-msg {
        font-size: 13px;
      }
    }
  }
}
