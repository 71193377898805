@import 'variables';
@import 'mixin';
$min-width: 100px;
$min-height: 40px;
$button-height: 14px;

.fs-menu-bg-light-gray {
  background-color: $lg-color1;
}

.fs-menu-bg-gray {
  background-color: $g-color;
}

.fs-menu-bordered {
  border: 1px solid $w-o-color;
}

.fs-menu {
  min-width: $min-width !important;
  min-height: $min-height !important;
  padding: 5px;
  border-radius: 4px;
}

.mat-mdc-menu-item:hover {
  text-decoration: none;
  border-radius: 8px;
  background-color: #f5f5f5 !important;
  color: $color_star_dust_approx !important;

  .mdc-list-item__primary-text {
    color: $color_star_dust_approx !important;
  }
}

.mat-mdc-menu-content {
  padding: 0px 4px !important;

  .mat-mdc-menu-item {
    min-height: auto !important;
    display: block;
    padding: 3px 20px;
    padding-bottom: 0 !important;
    padding-top: 1px !important;
    clear: both;
    color: $w-o-color;
    line-height: 1.428571429;
    font-weight: 400;
    height: auto;
    white-space: nowrap;
    margin-bottom: 0 !important;
  }
}

.mat-mdc-menu-item-text {
  font-size: 14px!important;
  font-weight: 400 !important;
}

.mat-mdc-menu-item.mdc-list-item {
  align-items: center;
  display: block;
  padding: 3px 20px;
  padding-bottom: 0 !important;
  padding-top: 1px !important;
  clear: both;
  height: auto;
  color: $w-o-color;
  font-weight: 400;
  line-height: 1.428571429;
  white-space: nowrap;
  margin-bottom: 0 !important;
}

.mat-mdc-menu-submenu-icon {
  color: $w-o-color !important;
  width: 10px;
  height: 15px;
  margin-right: -10px;
}

.mdc-list-item__primary-text {
  display: block;
  clear: both;
  height: auto;
  color: $w-o-color;
  font-weight: 400;
  line-height: 1.428571429;
  white-space: nowrap;
  font-family: Roboto, Helvetica Neue, sans-serif;
  font-size: 14px !important;
  font-weight: 400;
}

.fs-language-menu {
  background-color: $lg-color1 !important;
  color: $w-o-color !important;
  border: none !important;
  border-radius: 10px !important;
}
