/*===================== Model-Box CSS ==================*/

.theme-modelBox {
  background-color: rgba($bg-color, 0.6);
}

.xs-model-size {
  width: 100%;
  max-width: 320px;
  top: 50%;
  transform: translateY(-50%) !important;
  margin-top: 0px;
}

.overlay::after {
  position: fixed;
  content: '';
  background-color: rgba($bg-color, 0.5);
  top: 0px;
  width: 100%;
  height: 100%;
  bottom: 0px;
  left: 0px;
  z-index: 9;
}

label.custom-radio {
  margin: 0;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  font-weight: normal;
  display: block;
  z-index: 0;
  user-select: none;
  &:before {
    content: '';
    position: absolute;
    top: 7px;
    left: 0;
    width: 18px;
    height: 18px;
    background: #fff;
    border-radius: 20px;
  }
  input[type='radio'] {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    + .cb-title {
      display: block;
      padding: 5px 0;
      color: $w-o-color;
      font-size: 13px;
      &:before {
        content: '';
        opacity: 0;
        visibility: hidden;
        width: 10px;
        height: 10px;
        background: $blue-color;
        position: absolute;
        top: 50%;
        left: 4px;
        margin-top: -6px;
        border-radius: 20px;
      }
    }
    &:checked {
      + .cb-title {
        &:before {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}

.mf-sngle {
  button {
    background-color: $w-o-color;
    font-size: 13px;
    line-height: 1.5;
    font-weight: 400;
    text-transform: uppercase;
    color: $l-blk-color;
    padding: 5px 15px;
    position: relative;
    margin: 4px 0;
    border-radius: 50px;
    &:not(:disabled):hover {
      background-color: $w-color;
      color: $b-color;
    }
    border: none;
  }
}

.right-flag {
  padding-right: 20px;
  .cb-right {
    position: absolute;
    top: 5px;
    right: 0;
  }
}

.lg-model-size {
  width: auto;
  max-width: 750px;
}

.btm-images {
  margin-top: 5px;
  div {
    margin: 0 -5px;
    + div {
    }
    div {
      padding: 0 5px;
      text-align: center;
      float: left;
      .is-img {
        height: 100px;
        overflow: hidden;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .is-name {
        font-size: 11px;
        margin-top: 5px;
        word-break: break-all;
        line-height: 14px;
      }
      .isName {
        font-size: 10px;
        margin-top: 0px;
        word-break: break-all;
        line-height: 14px;
      }
    }
  }
}

.modal.fade.theme-modelBox.in {
  display: block;
}

.fs-popup-container-top {
  top: 8px !important;
}
