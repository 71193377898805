@import 'variables';

.fs-custom-approve-btn {
  color: $w-color !important;
  width: 100px;
  background: green;
  margin-bottom: 10px;
}

.fs-custom-reject-btn {
  color: $w-color !important;
  width: 100px;
  background: red;
  margin-bottom: 10px;
}

.fs-button-has-values {
  opacity: 1 !important;
}

.fs-button-selected:hover {
  -webkit-filter: brightness(100%);
  filter: brightness(100%);
}

.mdc-button__label {
  width: 100% !important;
}

.btn .material-icons {
  position: relative;
  top: -1px;
}
