@import 'variables';

%header-block-common {
  background: $blk-color-3;
  padding: 6px;
  display: inline-flex;
  width: 100%;
  line-height: 24px;
  max-height: 36px;
  .img-block {
    width: 40px;
    margin-right: 10px;
  }
}

%header-block-anchour {
  vertical-align: middle;
  text-decoration: none;

  &:hover {
    color: $w-color;
  }
}

.dep-exe-container {
  .block-header {
    padding: 5px 10px;
    margin: 20px 0;
    background: $blk-color-3;
    border-radius: 5px;
    position: relative;
    color: $g-color-9; //#cccccc;
    text-align: center;
    .search-project-comp {
      position: absolute;
      right: 10px;
      top: 5px;
    }
  }
  .flex-container {
    max-width: 1050px;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    .flex-item {
      margin: 0 15px 10px;
      width: 495px;
    }
    .project-block {
      background: $lg-color1;
      border-radius: 5px;
      .header-block {
        @extend %header-block-common;
        border-radius: 5px;
      }
    }
  }
  .body-content {
    padding: 8px 10px;
    div {
      margin-bottom: 0;
      div {
        padding: 4px 0;
        position: relative;
        list-style: disc;
        list-style-position: inside;
        line-height: 1;
        a {
          @extend %header-block-anchour;
        }
        a.add-favourite {
          width: 20px;
          height: 20px;
          position: absolute;
          line-height: 20px;
          text-align: center;
          right: 0;
          top: 2px;
        }
      }
    }
  }
  .department-block {
    position: relative;
    border-radius: 5px;
    .header-block {
      @extend %header-block-common;
      border-radius: 5px;
      a {
        @extend %header-block-anchour;
      }
    }
    .department {
      a {
        display: block;
        padding: 5px 10px;
        background: $lg-color1;
        position: absolute;
        border-radius: 2px;
        z-index: 1;
        top: 24%;
        right: 5px;
        line-height: 1;
        font-size: 10px;
      }
    }
  }
}

@media (min-width: 1024px) and (max-width: 1275px) {
  .flex-item {
    width: 450px !important;
    position: relative;
  }
  .launcher-container .project-title-favorite {
    right: 10px !important;
  }
}
