@media (max-width: 1024px) {
  .gallery-list .imgItem .imgItem-inner a .act-img img {
    max-height: 70%;
  }
}

@media (max-width: 991px) {
  .wrapper {
    padding-top: 195px;
  }
  .sidebar-main {
    top: 197px;
  }
  .header {
    .filter-bar {
    }
  }
}

.left-bar {
  .basic-filter {
    display: flex;
    div {
      margin-right: 5px;
      a {
        color: rgba(255, 255, 255, 0.4);
        text-decoration: none;
        font-weight: 400;
        padding: 2px;
        display: block;
        font-size: 12px;
      }
    }
  }
}

.right-bar {
  .adv-filter {
    display: flex;
    div {
      a {
        padding: 2px;
        img {
          max-width: 16px;
        }
      }
    }
  }
}
.gallery-list {
  .imgItem {
    .imgItem-inner {
      .imgex-links {
        div {
          margin: 0px 2px 0px !important;
          a {
            > span.char {
              font-size: 22px !important;
            }
            > img {
              max-width: 16px !important;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  #sidebarCollapse span {
    display: none;
  }
  .sidebar-main {
    top: 0;
    left: -266px;
  }

  .header .main-header {
    padding: 10px 14px;
  }
  .header .filter-bar {
    padding: 5px 15px;
  }
  .header .filter-bar .left-bar {
    width: 100%;
    display: block;
    text-align: center;
  }
  .header .filter-bar {
    width: 100%;
    text-align: center;
    display: block;
  }
  .header .filter-bar .right-bar {
    width: 100%;
    text-align: center;
    display: block;
  }
  .header .filter-bar .right-bar .adv-filter {
    text-align: center;
  }
  .header .main-header .h-top-left {
    display: block;
    width: 100%;
    text-align: center;
  }
  .header .main-header .dept-dropMenu {
    margin-bottom: 0px;
  }
  .header .main-header h4 {
    margin: 0px 0px 2px 0px;
  }
  .header .main-header .brand-logo {
    width: 30%;
  }
  .header .main-header .brand-logo a img {
    margin: 0px 0px auto;
  }
  .header .main-header .h-top-right {
    width: 70%;
  }
  .navbar-fixed-bottom,
  .navbar-fixed-top {
    position: static;
  }
  .wrapper {
    padding-top: 0px;
  }
  #sidebar .menu-item > span {
    font-size: 10px;
  }
  #sidebar .extra-menu-link > div > a img {
    max-width: 12px;
  }
  #sidebar div.components > div > .sub-menu-list > div > a {
    font-size: 12px;
  }
  .sidebar-main {
    min-width: 264px;
  }

  #sidebar.active {
    background-color: #1b1b1b;
  }
  .sidebar-main .btn-slide {
    background-color: #adadad;
  }
  .gallery-list .imgItem {
    min-height: inherit;
  }
  .sidebar .sidebar-main {
    left: 0px;
  }
}

@media screen and (max-width: 500px) {
  .login-btn {
    padding: 20px;
    .form-btm {
      a {
        margin: 10px 0px 5px !important;
        display: block;
      }
      label {
        display: block;
      }
    }
  }

  .gallery-list .imgItem .imgItem-inner a .act-img {
    height: 170px;
  }
}
