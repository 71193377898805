@import 'variables';

$row-height: 31px;
$table-scroll-border-size: 2px;
$table-border-size: 1px;
$table-border-width: 10px;
$table-header-row-height: 25px;

.mat-mdc-table {
  background-color: transparent !important;
  border: 1px solid $g-color-9;

  .mat-header-row {
    background-color: white !important;
    color: black;
    height: $row-height !important;

    th.mat-sort-header-sorted {
      color: black;
    }
  }

  .mat-mdc-row {
    min-height: auto !important;
    height: auto !important;
  }

  .mdc-data-table__cell:not(:first-child) {
    border-left: 1px solid $w-o-color;
  }

  .mat-header-cell:not(:last-child) {
    border-right: 1px solid black;
  }

  .mat-mdc-cell {
    text-align: center;
    align-items: center !important;
    padding: 0.25rem !important;
  }
}

.fs-sticky-header {
  &.mat-table {
    border-top: 0;
  }

  .mat-header-cell:not(:last-child) {
    border-right: 1px solid $header-table-border-color;
  }

  .fs-table-container th {
    background-color: $header-table-bg-color;
  }

  .mat-table .mat-cell {
    color: $white;
  }
}

.table-header-size {
  width: calc(
    100% - #{$table-border-width} - #{$table-scroll-border-size}
  ) !important;
}

.table-full-header-size {
  width: calc(100% - #{$table-border-size}) !important;
}

.table-last-cell-border {
  .mat-row {
    border-right: 1px solid $g-color-9 !important;;
  }
}

.fs-scroll-table-body {
  background-color: $header-table-bg-color;

  mat-table {
    overflow: auto;
    max-height: calc(65vh - 140px);
    background: $lg-color1 !important;
  }

  mat-header-row {
    background: $header-table-bg-color;
    min-height: $table-header-row-height !important;
    border-style: none;
    height: auto !important;
    align-items: stretch;
  }

  mat-header-cell:not(:last-child) {
    border-right: 1px solid $header-table-border-color !important;;
  }

  mat-header-cell:first-child {
    border-left: 1px solid $header-table-border-color !important;
  }

  mat-row:not(:last-child) {
    border-bottom: 1px solid $g-color-9 !important;;
  }

  .mat-table .mat-mdc-row {
    height: auto !important;
  }

  mat-row {
    min-height: auto !important;
    align-items: stretch;
    border-left: 1px solid $g-color-9 !important;
    border-right: 1px solid $g-color-9 !important;
    border-bottom: 1px solid $g-color-9 !important;

    .mat-cell:not(:last-child) {
      border-right: 1px solid $g-color-9 !important;
    }
  }

  .mat-table .mat-cell {
    border: 0;
  }

  mat-header-cell {
    color: #555555 !important;
    background-color: $header-table-bg-color;
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
    font-weight: normal;
    text-align: center;
    display: flex;
    justify-content: center;
  }
}

.fs-header-sort-color {
  .cdk-mouse-focused {
    background-color: $steel-blue-color !important;
  }
}
