@import 'variables';
@import 'font-mixins';

$fs-batch-dropdown-size: 45vh;
$fs-photographer-dropdown-size: calc(65vh - 150px);
$fs-keyword-dropdown-size: 45vh;

// TODO: RENAME it
.fs-selectbox {
  font-weight: 400;
  color: rgba(255, 255, 255, 0.4);
  font-size: 13px;
  width: 75px;
  margin: 0 auto;
  background: url(../../assets/images/btm-angle.png) right center no-repeat;

  &-label {
    margin: 0 0 5px;
    font-size: 15px;
    font-weight: normal;
    text-align: center;
  }
}

.fs-selectbox-dark {
  border-radius: 4px;
  background: $lg-color !important;
  border: 1px solid #585858 !important;

  .mat-mdc-select-value-text {
    color: $w-o-color !important;
  }

  .mat-mdc-select-arrow {
    color: $w-o-color !important;
  }

  .mat-mdc-select-placeholder {
    color: $w-o-color !important;
  }

  .mat-option-text,
  .mat-select-value-text,
  .mat-select-placeholder {
    color: rgba(255, 255, 255, 0.4) !important;
  }

  .mat-select-arrow {
    color: rgba(255, 255, 255, 0.4) !important;
  }

  &.mat-mdc-select-disabled .mat-select-trigger {
    opacity: 0.5;
  }

  &.mat-mdc-select-disabled {
    box-shadow: none !important;
  }
}

.fs-selectbox-dark-txt-sm {
  .mat-option-text,
  .mat-select-value-text,
  .mat-select-placeholder {
    @include fs-style(small);
  }
}

.fs-selectbox-options {
  &.mat-mdc-option {
    user-select: auto !important;
    color: $w-o-color !important;
    background: $lg-color !important;
    height: 24px;
    min-height: 3em;

    mat-pseudo-checkbox {
      display: none;
    }
  }

  &.mat-mdc-option:hover:not(.mat-option-mdc-disabled),
  .mat-mdc-option:focus:not(.mat-option-mdc-disabled) {
    color: $w-color !important;
    background: $lg-color !important;
  }

  &.mat-select-panel {
    background: $lg-color !important;
  }

  &.mat-select-panel
    .mat-mdc-option.mat-selected:not(.mat-option-mdc-multiple) {
    background: $lg-color !important;
  }

  &.mat-mdc-option .mat-pseudo-checkbox-minimal {
    display: none !important;
  }

  .mdc-list-item__primary-text {
    color: $w-o-color;
  }

  .mdc-list-item__primary-text:hover {
    color: #ffffff !important;
  }

  &.mdc-list-item--selected .mdc-list-item__primary-text {
    color: #ffffff !important;
  }
}

.mdc-menu-surface.mat-mdc-select-panel {
  padding: 0 !important;
  right: 13px;
  position: relative !important;
}

mat-optgroup {
  display: block;
  background: $lg-color !important;
  .mat-optgroup-label {
    display: flex;
    align-items: center;
    color: $w-o-color !important;
    div {
      @include fs-style(medium);
    }
  }
  mat-option {
    span {
      color: $w-o-color !important;
      @include fs-style(tiny);
    }
  }
}

.select-disabled {
  &.mat-select-disabled .mat-select-trigger {
    opacity: 0.35;
  }
}

.fs-actor-option-position-panel {
  position: absolute;
  top: 26px;
  left: 5px;
  border-radius: 0px !important;
  min-width: calc(100% + 22px) !important;
  border-top-style: none;
}

.fs-convert-option-position-panel {
  position: absolute;
  top: 43px;
  left: 5px;
  transform-origin: 0 !important;
  border-radius: 0px !important;
  min-width: calc(100% + 22px) !important;
  border-top-style: none;
}

.fs-tag-option-position-panel {
  position: absolute;
  top: 24px;
  left: 9px;
  border-radius: 0px !important;
  min-width: calc(100% + 22px) !important;
  border-top-style: none;
}

.mat-select-panel.fs-option-position-panel {
  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: $thumb-color !important;
  }

  &::-webkit-scrollbar-track {
    background-color: $lg-color;
    border-radius: 0px !important;
  }
}

.fs-keyword-dropdown-size-panel {
  max-height: $fs-keyword-dropdown-size !important;
}
