@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900');
@import 'variables';

/*           Global Styling
=========================================*/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  outline: none;
}

* {
  --scrollbar-thumb-color: rgb(0, 0, 1, 0.4) !important;
  --scrollbar-size: 10px !important;
  --scrollbar-border-radius: 5px !important;
}

*:hover {
  --scrollbar-thumb-color: rgb(0, 0, 1, 0.8) !important;
  --scrollbar-size: 10px !important;
  --scrollbar-border-radius: 5px !important;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block;
}
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}

html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

a {
  color: #27323c;
  text-decoration: none;
  transition: 0.3s;
  outline: none !important;
}
a:focus,
a:hover,
a:active {
  outline: 0;
}

button {
  outline: none !important;
}

b,
strong {
  font-weight: 700;
}
blockquote {
  margin: 1em 40px;
}
q {
  quotes: none;
}

q {
  &::before,
  &::after {
    content: '';
    content: none;
  }
}
small {
  font-size: 75%;
}

ul,
ol {
  list-style: none;
  display: block;
}

img {
  display: block;
  border: 0;
  -ms-interpolation-mode: bicubic;
  max-width: 100%;
}
svg:not(:root) {
  overflow: hidden;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

//           Form
// ==============================

button,
input,
select,
textarea {
  border-radius: 0px;
  -webkit-border-radius: 0px;
  border: 0px;
  font-size: 100%;
  color: #000;
  display: block;
  padding: 0 10px;
  text-transform: none;
  width: 100%;
}

select {
  box-shadow: none;
  appearance: menulist;
  &::-ms-expand {
    display: none;
  }
}

input[type='button'],
input[type='submit'],
button {
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  transition: 0.3s;
}
textarea {
  resize: none;
  padding: 10px;
  overflow: auto;
}
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: 40px;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

input[type='checkbox'],
input[type='radio'] {
  width: auto;
  display: none;
}
input[type='checkbox'] + label span {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-left: 4px;
  position: relative;
  top: -1px;
  background: #fff;
  border: 1px solid #dce0e1;
  vertical-align: middle;
  border-radius: 0px;
  -webkit-border-radius: 0px;
  cursor: pointer;
}
input[type='checkbox']:checked + label span {
  background: url(/images/tick.png) no-repeat center center #fff;
}
input[type='radio'] + label span {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-left: 4px;
  position: relative;
  top: -1px;
  background: #fff;
  border: 1px solid #a0a0a0;
  vertical-align: middle;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  cursor: pointer;
}
input[type='radio']:checked + label span {
  background: url(/images/dot.png) no-repeat center center #fff;
}

::-webkit-input-placeholder {
  color: rgba($b-color, 0.4);
  font-weight: 500;
  text-transform: uppercase;
}
:-moz-placeholder {
  color: rgba($b-color, 0.4);
  opacity: 1;
  font-weight: 500;
  text-transform: uppercase;
}
::-moz-placeholder {
  color: rgba($b-color, 0.4);
  opacity: 1;
  font-weight: 500;
  text-transform: uppercase;
}
:-ms-input-placeholder {
  color: rgba($b-color, 0.4);
  font-weight: 500;
  text-transform: uppercase;
}

.checkbox {
  padding-left: 20px;
  display: inline-block;
  vertical-align: middle;
  label {
    display: inline-block;
    position: relative;
    padding-left: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    &::before {
      content: '';
      display: inline-block;
      position: absolute;
      width: 20px;
      height: 20px;
      left: 0;
      margin-left: -20px;
      border: 1px solid #cccccc;
      border-radius: 3px;
      background-color: #fff;
      -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
      -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
      transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
      border-radius: 50%;
      border: 3px solid #ffff;
      margin-top: 2px;
    }
    &::after {
      display: inline-block;
      position: absolute;
      width: 10px;
      height: 10px;
      left: 5px;
      top: 5px;
      margin-left: -20px;
      padding-left: 3px;
      padding-top: 1px;
      font-size: 11px;
      color: #555555;
      border-radius: 50%;
      margin-top: 2px;
    }
  }
  input[type='checkbox'] {
    opacity: 0;
  }
  input[type='checkbox']:focus + label {
    &::before {
      outline: thin dotted;
      outline: 5px auto -webkit-focus-ring-color;
      outline-offset: -2px;
    }
  }
  input[type='checkbox']:checked + label {
    &::after {
      background-color: #0066cc;
      content: '';
    }
  }
}

//           TYPOGRAPHY
// ==============================

ul {
  padding: 0px;
}
ul li {
  list-style: none;
  cursor: default;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Roboto', sans-serif;
}
p,
a,
li,
strong,
label,
b,
button {
  font-family: 'Roboto', sans-serif;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  color: rgba($w-color, 0.4);
  line-height: 24px;
  font-weight: 400;
}

body {
  overflow: hidden;
  font-family: Roboto, 'Segoe UI', Tahoma, sans-serif;
  background-color: $bg1-color;
  color: $w-o-color;
}

.body-bg {
  background-color: $bg-color;
  color: $w-o-color;
}

a,
button,
select {
  cursor: pointer;
  border-radius: 6px;
  &:disabled,
  &[disabled],
  &.disabled {
    opacity: 0.5 !important;
    pointer-events: none;
  }
}

input,
textarea {
  cursor: auto;
  border-radius: 6px;
  &:disabled,
  &[disabled],
  &.disabled {
    opacity: 0.5 !important;
    pointer-events: none;
  }
}

select {
  font-weight: 400;
  padding: 8px 20px;
  background: #dbdbdb;
  border-radius: 4px;
}

textarea {
  background-color: #dbdbdb !important;
}

.container {
  width: 100%;
  max-width: 1000px;
}

.btn-sub {
  border-radius: 40px;
  background-color: $g-color-9;
  color: $l-blk-color;
  font-weight: 500;
  font-size: 13px;
  display: inline-block;
  width: auto;
  padding: 8px 10px;
  min-width: 80px;
  text-transform: uppercase;
}

/*------------------------------------*/
/*------ Inspiring Designs Css ------*/
/*----------------------------------*/
/*.mCustomScrollBox {max-height: inherit !important;}*/
.mCS-autoHide > .mCustomScrollBox > .mCSB_scrollTools,
.mCS-autoHide > .mCustomScrollBox ~ .mCSB_scrollTools {
  opacity: 1;
  filter: 'alpha(opacity=100)';
  -ms-filter: 'alpha(opacity=100)';
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  min-height: 10px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: $thumb-color;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 1, 0.8);
}

.transition-none {
  transition: none !important;
}

@media print {
  body,
  html {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .cnt-middle {
    margin-left: 320px;
  }
  .sidebar .cnt-middle {
    margin-left: 0px;
  }
  .sidebar .sidebar-main {
    left: -320px;
  }
}
