@import 'variables';

.fs-app-update-popup-panel {
  .fs-popup-content-wrapper {
    padding-top: 0 !important;
    background-color: $white;
  }

  .fs-popup-content {
    background-color: $white;
  }

  .fs-popup-pointer-panel {
    border-bottom: solid 14px #ffffff;
  }
}

.fs-app-updates-container {
  .fs-app-updates-title {
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.0012em;
    text-align: left;
  }

  .fs-app-update-feature:last-child {
    margin-bottom: 0 !important;
  }

  .fs-app-update-feature:first-child {
    line-height: 16px !important;
  }

  .fs-app-update-feature {
    font-size: 14px !important;
    font-weight: 300 !important;
    line-height: 24px !important;
    margin-bottom: 12px;
  }

  li {
    list-style: disc !important;
    display: list-item !important;
  }
}
