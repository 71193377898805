@import 'variables';
@import 'mixin';

.fs-radio-button {
  .radio-title {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.4);
  }

  .mdc-radio {
    padding: 0px !important;
  }

  .mdc-radio__background {
    z-index: -1;
  }

  .mdc-radio__inner-circle {
    max-width: 18px !important;
    max-height: 18px !important;
    border-width: 0px !important;
  }

  .mdc-form-field > label {
    user-select: auto;
    display: inline-block;
    order: 0;
    line-height: inherit;
    padding-left: 6px;
    padding-right: 0;
    margin: 0px !important;
    color: $w-o-color !important;

    span {
      color: $w-o-color !important;
    }
  }

  .mdc-radio__outer-circle {
    background-color: #fff;
    max-width: 18px !important;
    max-height: 18px !important;
    border-width: 0px !important;
  }

  &.mat-mdc-radio-button.mat-accent .mdc-radio__inner-circle,
  .mat-mdc-radio-button.mat-accent
    .mat-radio-ripple
    .mat-ripple-element:not(.mat-radio-persistent-ripple),
  .mat-mdc-radio-button.mat-accent.mat-radio-checked
    .mat-radio-persistent-ripple,
  .mat-mdc-radio-button.mat-accent:active &.mat-radio-persistent-ripple {
    background-color: #0066cc;
    border-color: #0066cc !important;
  }

  &.mat-mdc-radio-button.mat-accent {
    --mdc-radio-selected-pressed-icon-color: unset !important;
    --mdc-radio-selected-focus-icon-color: unset !important;
    --mdc-radio-selected-hover-icon-color: unset !important;
    --mdc-radio-selected-icon-color: unset !important;
    --mat-mdc-radio-ripple-color: unset !important;
    --mat-mdc-radio-checked-ripple-color: unset !important;
  }

  .mat-ripple {
    display: none;
  }

  .mat-mdc-radio-touch-target {
    height: 24px !important;
    width: 24px !important;
  }

  .mat-mdc-radio-button.mat-accent.mat-radio-checked &.mdc-radio__outer-circle {
    border-color: transparent;
  }

  &.mat-radio-disabled,
  &.mat-radio-disabled.mat-radio-label {
    opacity: 0.5;
  }

  &.mat-mdc-radio-button.mat-radio-disabled .mat-radio-label-content {
    color: $w-o-color !important;
  }

  .mdc-radio__background::before {
    background-color: transparent !important;
    opacity: 0 !important;
  }

  .mat-ripple, .mat-mdc-button-ripple {
    display: none;
  }
}
