.fs-input-hide-placeholder-focused {
  &:focus::placeholder {
    color: transparent !important;
  }
  &:focus::-webkit-input-placeholder {
    color: transparent !important;
  }
  &:focus:-moz-placeholder {
    color: transparent !important;
  } /* FF 4-18 */
  &:focus::-moz-placeholder {
    color: transparent !important;
  } /* FF 19+ */
  &:focus:-ms-input-placeholder {
    color: transparent !important;
  } /* IE 10+ */
}

.search-wrapper-cont {
  .mat-input-element {
    caret-color: auto !important;
  }
}
