@import 'variables';
@import 'mixin';
@import 'font-mixins';

.fs-dialog-panel {
  mat-dialog-container {
    font-family: 'Roboto', sans-serif;
    border-radius: 10px;
    background: transparent;
  }

  .mat-dialog-title {
    margin: -24px -24px 0 -24px;
    padding: 8px 16px;
    color: $w-o-color;
    background: $blk-color-3;
  }

  .mat-dialog-content {
    margin: 0 -24px -24px;
    background: $lg-color1;
    padding: 16px;
    color: $w-o-color;

    .mat-dialog-content-link {
      color: $w-o-color;
    }
  }

  .mat-dialog-actions {
    margin: 24px -24px -24px;
    padding: 0 16px;
    color: $w-o-color;
    background: $blk-color-3;
  }
}

.fs-dialog-btn {
  @include fs-button();
  @include fs-style(small);
}

.fs-dialog-success-btn {
  @include fs-button();
  @include fs-style(small);
  background-color: $color-light-green !important;
  color: white !important;

  &:hover {
    color: $color-light-green !important;
    background-color: white !important;
  }
}

.fs-dialog-overlay {
  background: $dialog-overlay-bg;
}

.fs-dialog-information-panel .fs-dialog-btn {
  color: $w-o-color;
}

.fs-dialog-full-screen-panel {
  width: 100%;
  height: 100%;
  max-height: 100vh;
  max-width: 100vw !important;

  .mat-mdc-dialog-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 0;

    & > *:first-child {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .mdc-dialog__content {
      display: flex !important;
      flex-direction: column;
      width: auto !important;
      height: 95%;
      max-height: unset !important;
    }
  }
}

.fs-dialog-width-900-panel {
  width: $dialog-width-900;
}

.fs-dialog-size-lg-plus-panel {
  width: $dialog-lg-plus-width;
}

.fs-dialog-size-ls-panel {
  width: $dialog-ls-width;
}

.fs-dialog-size-lg-panel {
  width: $dialog-lg-width;
}

.fs-dialog-width-1123-panel {
  width: $dialog-width-1132;
}

.fs-dialog-width-600-panel {
  width: $dialog-width-600;
}

.fs-dialog-width-300-panel {
  width: $dialog-width-300;
}

.fs-dialog-size-md-plus-panel {
  width: $dialog-md-plus-width;
}

.fs-dialog-size-md-panel {
  width: $dialog-md-width;
}

.fs-dialog-message-base-panel {
  .mat-dialog-title {
    font-size: 1.25em;
    font-weight: 400;
  }

  .mat-dialog-content {
    font-size: 1em;
    font-weight: 400;
  }
}

.fs-dialog-title {
  font-size: 16px;
  font-weight: 400;
}

.fs-dialog-link-panel {
  .mat-dialog-title {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 400;
    color: $w-o-color;
  }
}

.fs-dialog-no-shadow-panel {
  .mat-mdc-dialog-container {
    box-shadow: none !important;
  }
}

.fs-dialog-no-overflow-panel {
  mat-dialog-container {
    overflow: hidden !important;
  }

  .mat-dialog-content {
    overflow: hidden !important;
    height: auto;
    max-height: none;
  }
}

.fs-dialog-auto-width-panel {
  width: auto !important;

  mat-mdc-dialog-container {
    width: 100% !important;
  }
}

.fs-dialog-auto-height-panel {
  .mat-dialog-content {
    max-height: 100vh;
  }
}

.modal-title {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 400;
  color: $w-o-color;
}

.mat-mdc-dialog-container .mdc-dialog__title {
  background: $blk-color-3 !important;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  background: $lg-color1 !important;
  border-radius: 10px !important;
}

.mdc-dialog__actions {
  background-color: $blk-color-3 !important;
  padding: 0 16px !important;
}

.mat-mdc-dialog-container .mdc-dialog__title {
  color: inherit !important;
}

.mat-mdc-dialog-container .mdc-dialog__content {
  color: inherit !important;
  font-family: Roboto, sans-serif !important;
  font-size: 13px !important;
  line-height: 24px !important;
  font-weight: 400 !important;
  letter-spacing: normal !important;
}

.fs-dialog-caption-panel {
  .mdc-dialog__surface {
    background-color: transparent !important;
    background: transparent !important;
    box-shadow: none !important;
  }
}

.mat-mdc-dialog-container .mdc-dialog__title {
  padding: 8px 16px !important;
  margin: 0 !important;
}

.mdc-dialog__title::before {
  display: none !important;
}

.mdc-dialog .mdc-dialog__content {
  padding: 16px !important;
}
