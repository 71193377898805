@import 'variables';
@import 'mixin';

.fs-char-icon {
  font-size: 24px !important;
  line-height: 26px !important;
  font-weight: 400 !important;

  img {
    height: 16px;
    max-width: 16px;
    opacity: 0.3 !important;
    color: $w-o-color !important;

    &.highlighted-icon {
      opacity: 1 !important;
    }

    &:hover {
      transition: 0.5s all !important;
      opacity: 1 !important;
      color: $w-ol-color;
    }
  }
}

.fa-file-excel-o {
  margin-right: 5px;
}

.fa-file-excel-o,
.fa-file-pdf-o {
  font-size: 17px;
}

.global-help-icon-wrapper {
  position: relative;
}

.fa-step-backward,
.fa-step-forward {
  font-size: 17px !important;
  padding: 4px 8px;
}

.folder-icon-blank:hover {
  -webkit-filter: brightness(200%);
  filter: brightness(200%);
  transition: 0.5s all;
}

.fs-eye-icon-container {
  position: absolute;
  right: 24px;
  font-size: 15px;
  color: $black !important;
}
