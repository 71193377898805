@import 'variables';

.fs-close-btn {
  display: flex;
  background: transparent;
  padding: 0;
  opacity: 1;
  font-size: 22px;
  width: 22px;
  height: 22px;
  text-align: center;
  text-shadow: none;
  color: $w-o-color;

  :hover {
    color: $w-color;
  }
}
