@import 'variables';

.fs-chips {
  input {
    color: #1b1b1b;
    font-weight: 400;
    padding: 8px 15px;
    padding-left: 0 !important;
    background: #dbdbdb;
    border-radius: 6px;
  }

  .mat-form-field {
    width: 100%;
    min-height: 40px;
  }

  .mat-form-field-appearance-fill .mat-form-field-infix {
    width: 100%;
    min-height: 40px;
    border-radius: 6px;
    background-color: #dbdbdb;
    padding: 0 !important;
  }

  .mat-form-field-appearance-fill .mat-form-field-flex {
    padding: 0 !important;
  }

  .mat-form-field-wrapper {
    padding: 0;
  }

  .mat-form-field-infix {
    border-top: 0 !important;
  }

  .mat-chip-list-wrapper {
    margin: 0 -4px !important;
    margin-left: 32px !important;
  }

  .mat-form-field-underline {
    display: none;
  }

  .mat-mdc-standard-chip {
    border-radius: 3px !important;
    max-height: 27px !important;
    min-height: 27px !important;
  }

  .mat-mdc-chip.mat-mdc-standard-chip {
    color: $w-o-color;
    background-color: $ex-lg-color !important;
  }

  .mat-mdc-chip.mat-mdc-standard-chip::after {
    background: $ex-lg-color;
  }

  .mat-mdc-chip.mat-mdc-standard-chip:not(.mat-mdc-chip-disabled)
    .mat-mdc-chip-remove:hover {
    opacity: 0.4 !important;
  }

  .mat-mdc-chip.mat-mdc-standard-chip .mat-mdc-chip-remove {
    color: $w-o-color !important;
  }

  input {
    padding: 4px 8px !important;
  }

  .mdc-text-field {
    border-radius: 4px !important;
    background-color: $input-bg-color-1 !important;
    padding: 0px !important;
  }

  .mat-mdc-form-field-infix {
    margin-left: 32px !important;
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    min-height: auto !important;
  }

  .fs-chip-list {
    width: 100% !important;
  }

  .mdc-line-ripple {
    display: none !important;
  }

  .mat-mdc-chip-input {
    background-color: transparent !important;
    font-size: 13px !important;
  }

  .mat-mdc-chip.mat-mdc-standard-chip {
    color: $w-o-color !important;
    background-color: gray !important;
  }

  .mdc-evolution-chip__text-label {
    color: $w-o-color !important;
    font-size: 13px !important;
  }

  .mat-mdc-form-field-focus-overlay {
    background-color: transparent !important;
  }
  .mat-mdc-form-field-subscript-wrapper {
    display: none !important;
  }

  .mat-mdc-chip-input {
    margin-left: 0 !important;
  }
}

.fs-printtool-email-chips {
  .mat-chip-list-wrapper {
    margin-left: 16px !important;
  }
}
