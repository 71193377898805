@import 'variables';

$mat-default-popup-offset: 24px;
$fs-popup-border-radius: 10px;
$fs-popup-max-width: 480px;
$popup-width-210: 210px;

.fs-popup {
  border-radius: $fs-popup-border-radius;
}

.fs-popup-overlay {
  background: $dialog-overlay-bg;
}

.arrow-position-left {
  display: flex !important;
  flex-direction: row-reverse;
}

.arrow-position-right {
  display: flex !important;
}

.arrow-position-top {
  display: flex !important;
  flex-direction: column-reverse;
}

.fs-popup-container {
  background: transparent;
  display: block;
  box-sizing: border-box;
  outline: 0;
  width: 100%;
  height: 100%;
  min-height: inherit;
  max-height: inherit;
}

.fs-popup-content-no-padding-panel {
  .fs-popup-content-wrapper {
    padding: 0;
  }
}

.fs-popup-no-border-radius-panel {
  .fs-popup-content-wrapper {
    border-radius: 0;
  }
}

.fs-popup-content-wrapper {
  padding: 24px;
  overflow: auto;
  border-radius: $fs-popup-border-radius;

  & > div {
    background: transparent;
    border-radius: $fs-popup-border-radius;
    padding: $mat-default-popup-offset;
  }
}

.fs-popup-title {
  margin: -24px -24px 0 -24px;
  padding: 8px 16px;
  color: $w-o-color;
  background: $blk-color-3;
}

.fs-popup-content {
  margin: 0 -24px -24px;
  background: $lg-color1;
  padding: 16px;
  color: $w-o-color;
}

.fs-popup-actions {
  margin: 24px -24px -24px;
  padding: 8px 16px;
  color: $w-o-color;
  background: $blk-color-3;
}

.fs-popup-pointer-panel {
  border-bottom: solid 14px $lg-color1;
  border-left: solid 10px transparent;
  border-right: solid 10px transparent;
  position: relative;
  top: 2px;
  width: 0;
  height: 0;
}

.fs-popup-sm-panel {
  width: 320px;
}

.fs-popup-md-panel {
  .fs-popup-content-wrapper {
    width: 360px;
  }
}

.fs-popup-xs-panel {
  width: 180px;
}

.fs-popup-width-210-panel {
  width: $popup-width-210;
}

.fs-popup-max-width-panel {
  &.fs-popup-container {
    max-width: $fs-popup-max-width;
  }
}

.fs-popup-group-panel {
  .fs-popup-content {
    padding: 10px !important;
  }

  .fs-popup-content-wrapper {
    border-radius: 4px;
    padding-top: 0px !important;
  }
}

.fs-overlay-998 {
  z-index: 998 !important;
}
