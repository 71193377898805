@import 'variables';

$fs-toggle-bar-width: 25px;
$fs-toggle-bar-height: 15px;
$fs-toggle-slide-thumb-size: 10px;
$fs-toggle-slide-thumb-left-gap: 2px;
$fs-toggle-slide-checked-thumb-left-gap: $fs-toggle-bar-width -
  $fs-toggle-slide-thumb-size - $fs-toggle-slide-thumb-left-gap;

.fs-toggle {
  &-small {
    .mat-slide-toggle-bar {
      height: $fs-toggle-bar-height !important;
      width: $fs-toggle-bar-width !important;
    }

    .mat-slide-toggle-thumb {
      height: $fs-toggle-slide-thumb-size !important;
      width: $fs-toggle-slide-thumb-size !important;
    }

    &.mat-mdc-slide-toggle-checked .mat-slide-toggle-thumb-container {
      transform: translate3d(
        $fs-toggle-slide-checked-thumb-left-gap,
        0,
        0
      ) !important;
    }

    .mat-slide-toggle-thumb-container {
      width: $fs-toggle-slide-thumb-size !important;
      height: $fs-toggle-slide-thumb-size !important;
      top: 2px;
      transform: translate3d($fs-toggle-slide-thumb-left-gap, 0, 0);
    }
  }

  &-bar-dark-white {
    .mat-slide-toggle-bar {
      background: $dark-white;
    }
    &.mat-checked .mat-slide-toggle-bar {
      background-color: #186ba0 !important;
    }
  }

  &-thumb-white {
    .mat-slide-toggle-thumb {
      background: $white !important;
    }
  }

  &-thumb-active-white {
    .mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-thumb {
      background-color: $white !important;
    }
  }
}

.fs-toggle-dark {
  &.mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-thumb {
    background-color: #9fa8da !important;
  }

  &.mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: rgba(159, 168, 218, 0.5) !important;
  }

  .mat-slide-toggle-thumb {
    width: 24px !important;
    height: 24px !important;
    background-color: #bdbdbd !important;
  }

  .mat-slide-toggle-bar {
    width: 44px !important;
    height: 16px !important;
    background-color: rgba(255, 255, 255, 0.3) !important;
  }

  &.mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
    transform: translate3d(20px, 0, 0);
  }

  .mat-ripple {
    display: none;
  }
}

.toggle-children-wrapper-collapsed .toggle-children {
  transform: rotate(0deg);
  background-image: url(../../assets/images/rgt-angle.png) !important;
}

.tree-children {
  padding-left: 20px !important;
}

.toggle-children {
  background-image: url(../../assets/images/rgt-angle.png) !important;
  height: 8px;
  width: 9px;
  background-size: contain;
  display: inline-block;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  transform: rotate(90deg);
}

.toggle-children-placeholder {
  width: 13px !important;
  display: inline-block !important;
  height: 10px;
  position: relative !important;
  top: 1px;
  padding-right: 3px;
}

.node-content-wrapper {
  display: inline-block;
  padding: 2px 5px;
  border-radius: 2px;
  transition: background-color .15s,box-shadow .15s;
}

.mdc-switch {
  border-radius: 8px !important;
  height: 15px !important;
  width: 25px !important;
  background-color: $dark-white !important;
}

.mdc-switch:enabled .mdc-switch__track::after {
  background: $steel-blue-color !important;
}

.mdc-switch__handle::after {
  background: $white !important;
}

.mdc-switch__handle {
  height: 10px !important;
  width: 10px !important;
  background-color: $white !important;
}

.mdc-switch__icons {
  display: none !important;
}

.mat-mdc-slide-toggle .mdc-switch .mdc-switch__track {
  height: 15px !important;
  border-radius: 8px !important;
}

.mat-mdc-slide-toggle .mdc-switch .mdc-switch__handle-track {
  width: calc(100% - var(--mdc-switch-handle-width, 12px)) !important;
}

.mat-mdc-slide-toggle .mdc-switch .mdc-switch__handle {
  background-color: white !important;
}

.mat-mdc-slide-toggle
  .mdc-switch.mdc-switch--selected:enabled
  .mdc-switch__handle::after {
  background: white;
}

.mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__shadow {
  box-shadow: none;
}
