@import 'variables';
@import 'mixin';
@import 'font-mixins';

.fs-sm-txt {
  @include fs-style(small);
}

.fs-md-txt {
  @include fs-style(medium);
}

.fs-tiny-txt {
  @include fs-style(tiny);
}

.fs-txt-white-dark-color {
  color: $w-o-color !important;
}

.fs-bg-white-dark-color {
  background: $w-o-color !important;
}

.fs-txt-white-light-color {
  color: $w-ol-color !important;
}

.fs-txt-white-color {
  color: $white !important;
}

.fs-txt-white-grey-color-hover {
  @include fs-txt-hover($w-o-color, $w-ol-color);
}

.fs-text-color-yellow {
  color: $yellow !important;
}

.fs-text-color-light-red {
  color: $light-red-color !important;
}

.fs-text-color-red {
  color: $red !important;
}

.fs-text-color-shade-gray {
  color: $shade_gray-placeholder-color !important;
}

.fs-text-color-white {
  color: $white !important;
}

.fs-text-color-white-dark {
  color: $dark-white !important;
}

.fs-text-color-green {
  color: green !important;
}

.fs-text-color-light-green {
  color: $grn-color !important;
}

.fs-text-color-dark-green {
  color: $dark-green-text !important;
}

.fs-text-color-orange {
  color: $orange-text !important;
}

.fs-text-color-l-blk {
  color: $l-blk-color !important;
}

.fs-background-color-white {
  background: $white !important;
}

.fs-highlight-text {
  color: $white !important;
  font-weight: bold;
}

.fs-title-header-text {
  @include fs-style(medium);
  font-weight: 500 !important;
}

.fs-background-color-white-gray {
  background-color: $input-bg-color-1 !important;
}

.fs-text-color-light-black {
  color: $dark-l-color !important;
}

.fs-text-light-green-color {
  color: $color-light-green !important;
}

.fs-text-light-shade-pink-color {
  color: $light-shade-pink !important;
}
