.fs-cursor-pointer {
  cursor: pointer;
}

div.imgex-links div a img {
  transition: 0.5s all;
}

div.imgex-links div a img:hover {
  opacity: 0.8 !important;
  transition: 0.5s all;
}
