.iti {
  font-weight: 500;
  line-height: $input-line-height;
  color: $input-color;

  .iti__search-input {
    height: 40px;
    margin: 0;
    padding: 6px 12px;
  }
}

