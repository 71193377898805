@mixin size-sm {
  @media (max-width: 390px) {
    @content;
  }
}
@mixin size-md-down {
  @media (max-width: 599px) {
    @content;
  }
}
@mixin size-md-up {
  @media (min-width: 600px) {
    @content;
  }
}
@mixin size-l-down {
  @media (max-width: 899px) {
    @content;
  }
}
@mixin size-l-up {
  @media (min-width: 900px) {
    @content;
  }
}
@mixin size-xl-down {
  @media (max-width: 1199px) {
    @content;
  }
}
@mixin size-xl-up {
  @media (min-width: 1200px) {
    @content;
  }
}
@mixin size-xxl-down {
  @media (max-width: 1799px) {
    @content;
  }
}
@mixin size-xxl-up {
  @media (min-width: 1800px) {
    @content;
  }
}
