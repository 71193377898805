@import 'variables';
@import 'mixin';

.fs-round-checkbox {
  label {
    color: #000000;
    position: relative;
    padding-right: 30px;

    &:before {
      content: '';
      position: absolute;
      background-color: white;
      width: 20px;
      height: 20px;
      right: 0;
      border-radius: 3px;
    }

    &:after {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      right: 5px;
      top: 5px;
      border-radius: 50%;
    }
  }

  input[type='checkbox'] {
    display: none;
  }

  input[type='checkbox']:focus + label {
    &:before {
      // todo this doesnt work
      content: '';
      outline: 5px auto -webkit-focus-ring-color;
      outline-offset: -2px;
    }
  }

  input[type='checkbox']:checked + label {
    &:after {
      background-color: #0066cc;
      content: '';
    }
  }
}

.fs-checkbox {
  .mdc-checkbox__background {
    border: 1px solid $black !important;
    box-shadow: 0px 0px 20px $black inset !important;
    width: 20px !important;
    height: 20px !important;
    background: $white !important;
    border-radius: 4px !important;
  }

  .mat-ripple {
    display: none !important;
  }

  .mdc-checkbox__checkmark {
    color: $black !important;
  }

  .mdc-checkbox__checkmark-path {
    stroke-dasharray: 25.910259 !important;
    stroke-width: 2.1333333333px !important;
    stroke-dashoffset: 0 !important;
  }

  .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
    background: transparent !important;
  }

  .mdc-checkbox__ripple {
    background: transparent !important;
  }

  .mdc-form-field > label {
    padding-left: 0px !important;
    margin: 0 !important;
    font-size: 13px !important;
    color: $w-o-color !important;
  }

  .mat-mdc-checkbox-touch-target {
    height: 24px !important;
    width: 24px !important;
  }

  &.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__background {
    top: auto !important;
    left: auto !important;
  }

  &.mat-mdc-checkbox .mdc-checkbox {
    padding-top: 6px !important;
    padding-bottom: 6px !important;
    padding-left: 0px !important;
  }
}

mat-pseudo-checkbox {
  display: block !important;
  border: 1px solid $black !important;
  box-shadow: 0 0 20px $black inset !important;
  width: 20px !important;
  height: 20px !important;
  color: $black !important;
  background: $white !important;
  border-radius: 4px !important;

  &::after {
    color: $black !important;
  }
}
