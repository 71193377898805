@use '@angular/material' as mat;
@import '../functions/font-functions';

$w-color: #ffffff;
$w-o-color: rgba(255, 255, 255, 0.4);
$w-ol-color: rgba(255, 255, 255, 0.8);
$white: #fff;
$dark-white: #cccccc;
$dark-0-color: #555;
$dark-l-color: #373737;
$blue-lime-color: #45ccce;
$shade_gray-placeholder-color: #999999;
$blue-lime-color: #45ccce;
$color_shark_approx: #292929;
$black_30: rgba(0, 0, 0, 0.3);
$black: #000;
$thumb-color: rgba(0, 0, 1, 0.4);
$color_prairie_sand_approx: #9c3227;
$color-chicago-approx: #000;
$color_star_dust_approx: #666666;
$color-e-white: rgba(0, 0, 0, 0.15);
$b-color: #000000;
$b-o-black: rgba(40, 40, 40, 0.8);
$b-light-gray: #f5f5f5;
$l-blk-color: #1b1b1b;
$d-blk-color: #0d0d0d;
$blk-color-3: #333333;
$header-select-bg: #585858;
$header-select-border: #646464;
$light-shade-pink: #FFD5D2;
$header-table-bg-color: #ebedf0;
$header-table-border-color: #dddddd;
$ex-lg-color: #808080;
$lg-color: #515151;
$lg-color1: #494949;
$g-color: #3c3c3c;
$g-color-9: #929292;
$grn-color: #33d633;
$color-light-green: #578d61;
$blue-color: #0066cc;
$steel-blue-color: #186ba0;
$yellow-color: rgb(255, 255, 0);
$light-blue-color: #679abc;
$light-red-color: #fc6627;
$toast-pink: #f8b7bd;
$bg-color: #262626;
$bg1-color: #282828;
$shadow-light-color: rgba(0, 0, 0, 0.175);
$shadow-clr: rgba(#000000, 0.2);
$padd-0: 0px;
$marg-0: 0px;
$orange-text: #fe9900;
$green-text: #34d633;
$dark-green-text: #347c2c;
$red: red;
$strip-red: rgba(146, 0, 0, 0.3);
$strip-bg: #b71414;
$input-bg-color-1: #dbdbdb;
$breakpoint-desktop-med: 1199px;
$breakpoint-desktop-small: 991px;
$breakpoint-laptops-small: 1024px;
$breakpoint-tablet: 768px;
$breakpoint-mobile: 767px;
$breakpoint-mobile-med: 680px;
$breakpoint-mobile-small: 550px;
$down-index: 5;
$up-index: 10;
$normal_cursor: default;
$tag_cursor: crosshair;
$color_storm_dust_approx: #212121;
$color_alto_approx: #ddd;
$color_shady_lady_approx: #a9a9a9;
$color_tundora_approx: #444;
$input-bg-color: #b1b2b3;
$auth-input-bg-color: #9c9e9d; //TODO refactor and merge with another input-bg-coloe
$bg-color-red: #be1421;
$auth-login-button-bg-color: #95c11f; //TODO change
$approval-report-text-header: #1b1d1f;
$yellow: #fecb2f;

// dialog
$dialog-overlay-bg: rgba(38, 38, 38, 0.6);
$dialog-lg-width: 750px;
$dialog-lg-plus-width: 950px;
$dialog-md-width: 400px;
$dialog-md-plus-width: 470px;
$dialog-ls-width: 1200px;
$dialog-width-1132: 1132px;
$dialog-width-600: 600px;
$dialog-width-900: 900px;
$dialog-width-300: 300px;

// fonts
$fs-font-sizes: (
  normal-big: 15px,
  normal-plus: 13px,
  tiny: 11px,
);

$fs-font-sizes-line-height: (
  normal-plus: 18px,
  tiny: 13px,
);

$fs-fonts: (
  main: 'Roboto',
);

$fontConfig: (
  small: mat.define-typography-level(
    $font-family: fontFamily(main),
    $font-weight: normal,
    $font-size: fontSize(normal-plus),
    $line-height: fontSizeLineHeight(normal-plus),
    $letter-spacing: normal,
  ),
  medium: mat.define-typography-level(
    $font-family: fontFamily(main),
    $font-weight: normal,
    $font-size: fontSize(normal-big),
    $line-height: fontSizeLineHeight(normal-plus),
    $letter-spacing: normal,
  ),
  tiny: mat.define-typography-level(
    $font-family: fontFamily(main),
    $font-weight: normal,
    $font-size: fontSize(tiny),
    $line-height: fontSizeLineHeight(tiny),
    $letter-spacing: normal,
  ),
);
