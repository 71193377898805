//@import "~@ng-select/ng-select/themes/default.theme.css";
@import 'variables';

.ngx-pagination {
  display: inline-block;
}

.ngx-pagination .current {
  padding: 3px 6px !important;
  background: #5d6163 !important;
  color: #fefefe !important;
  cursor: default;
  border-radius: 16px;
  font-size: 12px;
}

.page-num-counter.active {
  border: 1px solid #6b6b6b !important;
  border-radius: 10px;
  margin-left: 8px;
  margin-right: 8px;
  padding-left: 20px;
  padding-right: 20px;
}

.page-num-counter {
  span {
    padding: 4px 8px;
  }
}

.pagination_width .pagination {
  display: flex;
  justify-content: center;
  min-width: 320px;
}

.pagination-row-icon-padding {
  padding: 4px 8px;
}

div.pagination div .fa:hover {
  color: #ffffff !important;
  opacity: 1 !important;
  transition: 0.5s all;
}

.notransition {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}

.notransition:hover {
  -webkit-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}

.mat-mdc-paginator-container {
  background-color: $lg-color1;
  color: $g-color-9;
}
.mat-mdc-icon-button .mat-mdc-button-persistent-ripple::before {
  opacity: 0 !important;
}
.mat-mdc-paginator-icon {
  fill: $g-color-9 !important;
}
.mat-ripple, .mat-mdc-button-ripple {
  display: none;
}
