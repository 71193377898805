@import 'mixin';

$left-menu-width: 320px;
$video-image-icon-small: 40px;
$video-image-icon-medium: 51px;
$left-menu-width: 320px;
$approve-reject-icon-lg-size: 35px;
$approve-reject-icon-size: 22px;
$left-menu-width: 320px;
$color-light-green: #578d61;

.cnt-middle {
  transition: all 0.3s;
  text-align: center;
}

.image-gallery {
  overflow: auto;
  min-height: calc(100vh - 203px);
  height: calc(100vh - 112px);
  max-height: calc(100vh - 112px);
  padding: 20px;
}

.gallery-list {
  font-size: 0px;
  text-align: left;
  display: grid;
  place-content: center;
  align-self: flex-start;
  justify-items: center;
  grid-template-columns: repeat(auto-fill, 350px);
  white-space: nowrap;
  .imgItem {
    font-size: 12px;
    display: inline-block;
    vertical-align: top;
    width: 309px;
    max-width: 100%;
    margin: 0px 10px 25px 10px;
    .imgItem-inner {
      text-align: center;
      color: $w-o-color;
      text-decoration: none;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 412px;
      a {
        position: relative;
        display: block;
        .act-img {
          position: relative;
          span {
            display: inline-block;
          }
          .loadImage {
            position: absolute;
          }
          img {
            object-fit: cover;
            max-width: initial;
          }
        }
      }
    }
    .act-desc {
      .note_li {
        ::-webkit-scrollbar {
          width: 0px !important;
        }
        ::-webkit-scrollbar-thumb {
          width: 0px !important;
        }
        form {
          margin-top: 15px;
          .right-save-btn {
            margin-top: 30px;
            margin-bottom: 10px;
          }
        }
      }
      p {
        color: $w-o-color;
        margin: 10px 0px 12px;
        line-height: 12px;
      }
      .imgex-links {
        div {
          list-style: nonel;
          display: inline-block;
          position: relative;
          margin: 0px 5px 10px;
          vertical-align: middle;
          a {
            display: inherit;
            width: 18px;
            height: 18px;
            padding: 2px;
            text-decoration: none;
            img {
              max-width: 20px;
              opacity: 0.3;
            }
            img.red-close {
              opacity: 1;
            }
            span.char {
              font-size: 26px;
              font-weight: 900;
              color: $w-o-color;
              text-transform: uppercase;
            }
            .highlight {
              color: $w-color !important;
              opacity: 1;
            }
            &:hover {
              color: $w-color;
              img {
                opacity: 1;
              }
              span.char {
                color: $w-color;
              }
            }
          }
        }
      }
    }
  }
}

.medium-img {
  .video-image-icon {
    font-size: $video-image-icon-medium !important;
  }

  .admin-user-wrapper {
    width: 210px;
  }
}

.small-img {
  .video-image-icon {
    font-size: $video-image-icon-small !important;
  }

  .admin-user-wrapper {
    width: 210px;
  }
}

#sidebar.active {
  background-color: $bg-color;
  .btn-slide {
    > img {
      transform: rotate(180deg);
    }
  }
}

#sidebar {
  .sidebar-header {
    .search-filter {
      padding: 10px 10px 10px 10px;
      .filter-btn {
        background-color: $lg-color1;
        border-radius: 50px;
        height: 35px;
        width: 300px;
        max-width: 300px;
        line-height: 28px;
        text-align: left;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        color: rgba(255, 255, 255, 0.4);
        padding: 5px 20px;
        letter-spacing: 1px;
        position: relative;
        display: block;
        text-decoration: none;
        text-align: center;
        > .fa-caret-right {
          right: 15px;
          top: 10px;
          font-size: 20px;
          vertical-align: middle;
          position: absolute;
        }
      }
    }
    .basic-filter {
      padding: 5px 0 0;
      .project-info-ul {
        margin: 0px;
        div {
          line-height: 20px;
        }
      }
    }
  }
  .open {
    .menu-item {
      &:before {
        content: '\f0d7';
        line-height: 1.2;
      }
    }
  }
  div.sideBar-menu {
    margin-bottom: 2px;
  }
  div.components {
    .right-content {
      position: relative;
    }
    > div {
      color: $w-o-color;
      a {
        padding: 5px 20px;
        display: block;
        color: $w-o-color;
        text-transform: uppercase;
      }
      .sub-menu-list {
        background-color: $l-blk-color;
        display: none;
        margin: 10px 0;
        div {
          position: relative;
          a {
            text-decoration: none;
            text-transform: none;
            padding: 0px 20px;
            font-weight: 500;
            letter-spacing: 1px;
            > i {
              margin-right: 10px;
            }
            &:hover,
            &:focus {
              color: $w-color;
            }
          }
        }
      }
      .accord_dis {
        display: block !important;
      }
      div.active > a,
      a[aria-expanded='true'] {
        color: #fff;
        background: $d-blk-color;
      }
      a[data-toggle='collapse'] {
        position: relative;
      }
    }
  }
  .menu-item {
    padding: 2px 2px;
    position: relative;
    background-color: $d-blk-color;
    cursor: default;
    transition: all 300ms;
    > span {
      display: inline-block;
      text-transform: uppercase;
      font-weight: 400;
      color: $w-o-color;
      cursor: default;
      padding-left: 20px;
      letter-spacing: 1px;
    }
  }
  .menu-item.my_folders {
    padding: 0px !important;
  }
  .menu-item:before {
    display: block;
    font-family: 'FontAwesome';
    position: absolute;
    left: 20px;
    font-size: 20px;
    vertical-align: middle;
    color: $w-o-color;
  }
}

.sub-menu-list.sub-menu-text-spacing {
  span {
    text-decoration: none;
    text-transform: none;
    font-weight: 500;
    letter-spacing: 1px;
  }
}

#sidebar {
  .right-content {
    .menu-item {
      padding-right: 130px;
    }
    .extra-menu-link {
      position: absolute;
      top: 5px;
      right: 10px;
      display: none;
    }
  }
  .open {
    .extra-menu-link {
      display: block;
    }
  }
}

.border-active {
  border: 2px solid blue !important;
}

.single-filter {
  div {
    margin: 0;
  }
  h4 {
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 400;
    padding: 10px 30px 10px 0;
    position: relative;
    display: none;
    &:after {
      content: '\f107';
      font-family: FontAwesome;
      position: absolute;
      top: 8px;
      right: 0;
    }
  }
}

.sendmail-msg {
  padding-left: 32px !important;
  font-size: 1em;
  background-color: #dbdbdb;
  min-height: 34px;
  font-weight: normal;
  line-height: 1.2;
  height: auto;
}

/* New -hover */

.imgItem-inner-child:after,
.imgItem-inner:after,
.imgItem-inner-child:before,
.imgItem-inner:before {
  position: absolute;
  right: 0px;
  content: '';
  background-repeat: no-repeat;
  top: 0px;
  background-image: url('../../assets/images/img-corner-gray.png');
  width: 21px;
  height: 21px;
}

.imgItem-inner-child.img-corner-fff100:after,
.imgItem-inner.img-corner-fff100:after,
.imgItem-inner-child.img-corner-fff100:before,
.imgItem-inner.img-corner-fff100:before {
  background-image: url('../../assets/images/img-corner-fff100.png');
}

.imgItem-inner-child.img-corner-ff8c00:after,
.imgItem-inner.img-corner-ff8c00:after,
.imgItem-inner-child.img-corner-ff8c00:before,
.imgItem-inner.img-corner-ff8c00:before {
  background-image: url('../../assets/images/img-corner-ff8c00.png');
}

.imgItem-inner-child.img-corner-e81123:after,
.imgItem-inner.img-corner-e81123:after,
.imgItem-inner-child.img-corner-e81123:before,
.imgItem-inner.img-corner-e81123:before {
  background-image: url('../../assets/images/img-corner-e81123.png');
}

.imgItem-inner-child.img-corner-ec008c:after,
.imgItem-inner.img-corner-ec008c:after,
.imgItem-inner-child.img-corner-ec008c:before,
.imgItem-inner.img-corner-ec008c:before {
  background-image: url('../../assets/images/img-corner-ec008c.png');
}

.imgItem-inner-child.img-corner-68217a:after,
.imgItem-inner.img-corner-68217a:after,
.imgItem-inner-child.img-corner-68217a:before,
.imgItem-inner.img-corner-68217a:before {
  background-image: url('../../assets/images/img-corner-68217a.png');
}

.imgItem-inner-child.img-corner-00188f:after,
.imgItem-inner.img-corner-00188f:after,
.imgItem-inner-child.img-corner-00188f:before,
.imgItem-inner.img-corner-00188f:before {
  background-image: url('../../assets/images/img-corner-00188f.png');
}

.imgItem-inner-child.img-corner-00bcf2:after,
.imgItem-inner.img-corner-00bcf2:after,
.imgItem-inner-child.img-corner-00bcf2:before,
.imgItem-inner.img-corner-00bcf2:before {
  background-image: url('../../assets/images/img-corner-00bcf2.png');
}

.imgItem-inner-child.img-corner-00b294:after,
.imgItem-inner.img-corner-00b294:after,
.imgItem-inner-child.img-corner-00b294:before,
.imgItem-inner.img-corner-00b294:before {
  background-image: url('../../assets/images/img-corner-00b294.png');
}

.imgItem-inner-child.img-corner-009e49:after,
.imgItem-inner.img-corner-009e49:after,
.imgItem-inner-child.img-corner-009e49:before,
.imgItem-inner.img-corner-009e49:before {
  background-image: url('../../assets/images/img-corner-009e49.png');
}

.imgItem-inner-child.img-corner-bad80a:after,
.imgItem-inner.img-corner-bad80a:after,
.imgItem-inner-child.img-corner-bad80a:before,
.imgItem-inner.img-corner-bad80a:before {
  background-image: url('../../assets/images/img-corner-bad80a.png');
}

.imgItem-inner-child.img-corner-1069c9:after,
.imgItem-inner.img-corner-1069c9:after,
.imgItem-inner-child.img-corner-1069c9:before,
.imgItem-inner.img-corner-1069c9:before {
  background-image: url('../../assets/images/img-corner-1069c9.png');
}

.imgItem-inner-child:after {
  background-position: -29px 0;
}

.imgItem-inner-child:before {
  left: 0px;
  background-position: 0 0;
}

.imgItem-inner:before {
  left: 0px;
  background-position: 0 -29px;
  bottom: 0px;
  top: auto;
}

.imgItem-inner:after {
  right: 0px;
  top: auto;
  background-position: -29px -29px;
  bottom: 0px;
}

.gallery-list .imgItem:hover .imgItem-inner-child:after {
  background-position: -29px 0;
}

.gallery-list .imgItem:hover .imgItem-inner-child:before {
  background-position: 0 0;
}

.gallery-list .imgItem:hover .imgItem-inner:before {
  background-position: 0 -29px;
}

.gallery-list .imgItem:hover .imgItem-inner:after {
  background-position: -29px -29px;
}

.gallery-list .imgItem:hover .act-desc > span.image-name {
  color: white;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

.launcher-container .ng-scroll-content {
  min-width: auto !important;
}

.launcher-container .project-title-favorite,
.launcher-container .project-title {
  color: rgba(255, 255, 255, 0.4);
  vertical-align: middle !important;
  text-decoration: none !important;
}

.launcher-container .project-title-favorite {
  display: inline-block !important;
  width: 20px !important;
  height: 20px !important;
  position: absolute !important;
  line-height: 20px !important;
  text-align: center !important;
  right: 20px;
  top: 10px !important;
}

.image-tag-wrapper {
  z-index: $down-index;
  position: relative;
  .image-tag-wrapper_img {
    position: absolute;
    color: $w-color;
    z-index: $up-index;
    cursor: $normal_cursor;
    text-align: left;
    font-weight: bold;
    padding-left: 5px;
    padding-right: 5px;
    cursor: pointer;
  }
  input[type='text'] {
    background: #999999;
  }
}

.remove-data-list {
  margin: 0;
  max-height: 400px;
  .remove-data-list_item {
    margin: 0;
    width: 100%;
    display: inline-block;
    span {
      padding: 2px 0px 4px 1px;
      text-align: left;
    }
    > span:first-child {
      margin-left: 40px;
    }
  }
}

.send-email-body div.ui-inputtext div.ui-chips-input-token input[type='text'],
.link-email-body div.ui-inputtext div.ui-chips-input-token input[type='text'] {
  width: 380px !important;
  font-size: 12px;
  padding-right: 0px !important;
}

.sign-off-main tree-viewport,
.wide-edit-main tree-viewport {
  overflow: initial !important;
}

img.reject_image:hover + span {
  border: 2px dashed #fff !important;
  cursor: grabbing;
}

.fs-image-info-toggle-container {
  right: 10px;
  top: 6px;
  position: absolute;
}

.image-color-cont {
  width: 100%;
  float: left;
  min-height: 15px;
  line-height: 6px;
  margin-top: 3px;
}

.fs-approved-rejected-icon-size {
  font-size: $approve-reject-icon-size !important;
}

.fs-album-color-container {
  .fs-album-color-round {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 100%;
  }
}

.fs-image-panel-container {
  .image-container {
    position: relative;
    margin-bottom: -8px;

    .reject-image,
    .exclusive-image {
      z-index: 1;
      position: absolute;
      width: 50%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: grabbing;
    }

    .reject-image-small {
      width: 30%;
    }

    .approve-icon,
    .rejected-icon {
      display: inline-block;
      width: $approve-reject-icon-size;
      height: $approve-reject-icon-size;
      position: absolute;
      z-index: 1;
      top: calc(-1 *#{$approve-reject-icon-size}/ 2);
      right: calc(-1 *#{$approve-reject-icon-size}/ 2);

      &.apr-rej-large-img {
        top: -14px;
        right: -8px;
      }

      &.apr-rej-medium-img {
        top: -15px;
        right: -8px;
      }

      &.apr-rej-small-img {
        top: -18px;
        right: -8px;
      }
    }

    .bg-sprite {
      background-image: url('../../assets/images/icon-sprite.png') !important;
    }

    .hover-border {
      border: 2px solid transparent;
    }

    .image-protect {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      cursor: pointer;
      z-index: 2;
      background: transparent;

      img {
        width: inherit;
        height: inherit;
      }

      &:hover {
        border: none !important;
      }
    }

    .image {
      border: 2px solid transparent;

      &.selected-border {
        border: 2px solid blue !important;
        transition: all 0.3s;
      }

      &.rejected-border {
        border: 2px solid red;
      }

      &.approved-border {
        border: 2px solid green;
      }

      &.notes-border {
        border: 2px solid yellow;
      }
    }

    .hover-border:hover {
      border: 2px dashed blue;
      transition: all 0.3s;
    }

    &.last-select-border {
      .hover-border {
        border-style: dashed !important;
        border-color: #fff !important;
      }
    }
  }
}

.fs-reject-icon-sp {
  display: block;
  width: $approve-reject-icon-lg-size;
  height: $approve-reject-icon-lg-size;
  background-position: -36px -318px;
}

.fs-approve-icon-sp {
  display: block;
  width: $approve-reject-icon-lg-size;
  height: $approve-reject-icon-lg-size;
  background-position: -71px -318px;
}

.large-img .image-container {
  min-height: 204px;
  min-width: 305px;
}

.medium-img .image-container {
  min-height: 142px;
  min-width: 204px;
}

.small-img .image-container {
  min-height: 92px;
  min-width: 129px;
}
